// src/components/SearchBox.js
import React, { useState } from 'react';
import axios from 'axios';
import '../App.css'; // Import the CSS

const SearchBox = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setResults('');

        try {
            const response = await axios.post('https://server-5974.onrender.com/search', { query });
            setResults(formatResponse(response.data));
        } catch (error) {
            console.error('Error searching:', error);
            setError('Error searching. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const formatResponse = (text) => {
        // Split the response text into shorter lines or paragraphs
        return text.split('\n').map((line, index) => <p key={index}>{line}</p>);
    };

    return (
        <div>
            <form onSubmit={handleSearch}>
                <input
                    className='textinput'
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Enter a region, city or country"
                />
                <button type="submit">Search</button>
            </form>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="results">
                <h2>Results:</h2>
                <div className="formatted-results">
                    {results}
                </div>
            </div>
        </div>
    );
};

export default SearchBox;
