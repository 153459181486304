// src/App.js
import React from 'react';
import './App.css'; // Import the CSS
import SearchBox from './components/SearchBox';
import logo from './images/logo.png'; // Import the logo image

const App = () => {
    return (
        <div className="App">
            <img src={logo} alt="AI Travel Finder Logo" className="logo" /> {/* Display the logo */}
            <SearchBox />
        </div>
    );
};

export default App;
